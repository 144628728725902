.social-networks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  gap: 1.2rem;

  a {
    padding: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
