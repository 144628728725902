.menu-button {
  --button-size: 4rem;
  --border-width: 2px;
  width: var(--button-size);
  height: var(--button-size);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: var(--border-width) solid transparent;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease-in-out;

  &.is-active {
    border-color: var(--color-text);

    .hamburger {
      transform: rotate(45deg);

      &__line {
        --line-translate: 10px;

        &:nth-child(1) {
          transform: translateY(var(--line-translate));
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateY(calc(var(--line-translate) * -1)) rotate(90deg);
        }
      }
    }
  }

  .hamburger {
    width: 100%;
    position: relative;
    transition: all 0.3s ease-in-out;

    &__line {
      width: var(--button-width);
      height: 2px;
      display: block;
      background-color: var(--color-text);
      transition: all 0.3s ease-in-out;

      &:nth-child(2) {
        margin: 0.8rem 0;
      }
    }
  }
}
