// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.color {
    &-primary {
        color: var(--color-primary);
    }

    &-secondary {
        color: var(--color-secondary);
    }
}

.uppercase {
    text-transform: uppercase;
}

.monospace {
    font-family: var(--font-monospace);
}

.bold {
    font-weight: 900;
    letter-spacing: 0.8px;
}

.blur {
    filter: blur(4px);
}

.fixed {
    overflow: hidden;
}

.fade-in {
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.show {
        opacity: 1;
    }
}
