// -----------------------------------------------------------------------------
// This file contains debug classes.
// -----------------------------------------------------------------------------

/**
 * Debug class for the reponsive of a component.
 */
.debug {
  // Modifier to debug an element.
  &--1 {
    background-color: red;
    border: 1px solid red;
  }

  // Modifier to debug an element.
  &--2 {
    background-color: green;
    border: 1px solid green;
  }

  // Modifier to debug an element.
  &--3 {
    background-color: blue;
    border: 1px solid blue;
  }

  // Modifier to debug an element.
  &--4 {
    background-color: yellow;
    border: 1px solid yellow;
  }

  // Modifier to debug an element.
  &--5 {
    background-color: purple;
    border: 1px solid purple;
  }

  @include xs {
    border: 2px solid red;
  }

  @include sm {
    border: 2px solid green;
  }

  @include md {
    border: 2px solid blue;
  }

  @include lg {
    border: 2px solid orange;
  }

  @include xl {
    border: 2px solid purple;
  }
}
