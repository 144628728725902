.page {
  width: 100%;
  height: 100%;
  padding: 0 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include lg {
    padding: 0 10rem;
  }

  @include md {
    padding: 0 8rem;
  }

  @include sm {
    padding: 0 4rem;
  }

  @include xs {
    padding: 0 2rem;
  }

  &__content {
    width: 100%;
    max-width: 1200px;
  }
}
