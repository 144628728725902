.contact {
    .contact-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__info {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;

            @include sm-lt {
                flex-direction: column;
                align-items: center;
            }

            .message {
                &__sentence {
                    max-width: 50rem;
                    line-height: 2.3rem;
                    font-size: 1.6rem;
                    font-weight: 100;
                    margin-bottom: 0;

                    &:first-child {
                        margin-bottom: 2rem;
                    }
                }
            }

            .location {
                width: 35rem;
                margin-left: 5rem;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: var(--color-grey);
                border-radius: 4px;
                box-shadow: var(--box-shadow);

                @include sm-lt {
                    margin-left: 0;
                    margin-top: 5rem;
                }

                @include xs {
                    width: 100%;
                }

                &__title {
                    text-align: start;
                    font-size: 1.8rem;
                    font-weight: 600;
                    white-space: nowrap;
                    margin-bottom: 3rem;

                    @include xs {
                        margin-bottom: 5rem;
                        white-space: normal;
                    }

                    i {
                        color: var(--color-primary);
                        font-size: inherit;
                        margin-right: 1rem;
                    }
                }

                &__city {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    margin-bottom: 0;
                    letter-spacing: 1px;
                    word-spacing: 0.5rem;
                    font-size: 1.5rem;
                    font-weight: 400;

                    &::after {
                        content: "";
                        display: block;
                        width: 5rem;
                        height: 1px;
                        margin: 1.5rem 0;
                        background-color: var(--color-white);
                    }

                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        &__button {
            margin: 10rem 0 16rem 0;
        }
    }
}
