// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

// --------------------
// Media queries.
// --------------------

/// Media query for mobile devices.
@mixin mobile {
  @media only screen {
    @content;
  }
}

/// Media query for mobile devices with landscape orientation.
@mixin mobile-landscape {
  @media (orientation: landscape) and (height < 500px) {
    @content;
  }
}

/// Media query for XS devices.
@mixin xs {
  @media (max-width: map-get($breakpoints, "xs-max")) {
    @content;
  }
}

/// Media query for small devices.
@mixin sm {
  @media (min-width: map-get($breakpoints, "sm-min")) and (max-width: map-get($breakpoints, "sm-max")) {
    @content;
  }
}

/// Media request for small devices and less.
@mixin sm-lt {
  @media (max-width: map-get($breakpoints, "sm-max")) {
    @content;
  }
}

/// Media query for medium devices.
@mixin md {
  @media (min-width: map-get($breakpoints, "md-min")) and (max-width: map-get($breakpoints, "md-max")) {
    @content;
  }
}

/// Media query for small devices and less.
@mixin md-lt {
  @media (max-width: map-get($breakpoints, "md-max")) {
    @content;
  }
}

/// Media query for large devices.
@mixin lg {
  @media (min-width: map-get($breakpoints, "lg-min")) and (max-width: map-get($breakpoints, "lg-max")) {
    @content;
  }
}

/// Media query for large devices and less.
@mixin lg-lt {
  @media (max-width: map-get($breakpoints, "lg-max")) {
    @content;
  }
}

/// Media query for XL devices.
@mixin xl {
  @media (min-width: map-get($breakpoints, "xl-min")) {
    @content;
  }
}

/// Add media query properties for a specific media.
/// @param $media - Media.
@mixin media-queries($media) {
  @if ($media == xs) {
    @include xs() {
      @content;
    }
  } @else if ($media == sm) {
    @include sm() {
      @content;
    }
  } @else if ($media == sm-lt) {
    @include sm-lt() {
      @content;
    }
  } @else if ($media == md) {
    @include md() {
      @content;
    }
  } @else if ($media == md-lt) {
    @include md-lt() {
      @content;
    }
  } @else if ($media == lg) {
    @include lg() {
      @content;
    }
  } @else if ($media == lg-lt) {
    @include lg-lt() {
      @content;
    }
  } @else if ($media == xl) {
    @include xl() {
      @content;
    }
  }
}

/// Add media query rules from a map.
/// @param {Map{Map}} $properties - Style rules according to the media.
@mixin media-queries-rules($properties) {
  @each $media, $rules in $properties {
    @include media-queries($media) {
      @each $rule, $value in $rules {
        #{$rule}: $value;
      }
    }
  }
}

// --------------------
// Other.
// --------------------

/// Default page style.
@mixin page {
  width: 100%;
  max-width: 1200px;
  padding: 0 4rem;

  @include xs {
    padding: 0 2rem;
  }
}

/// Background image.
/// @param {String} $url - URL of the image.
@mixin background-image($url: none) {
  @if ($url != none) {
    background-image: get-image($url);
  }

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
