.skills {
  .section__container h2.xs-no-mb {
    @include xs {
      margin-bottom: 2rem;
    }
  }

  .skills-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    margin-top: 10rem;

    @include xs {
      margin-top: 0rem;
      grid-template-columns: auto;
      grid-template-rows: repeat(3, auto);
    }

    .skills-block {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @include xs {
        padding: 4rem 0;
      }

      &:nth-child(2) {
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 1px;
          height: 100%;
          background-color: var(--color-primary-transparent);

          @include xs {
            width: 40%;
            height: 1px;
            top: auto;
            bottom: auto;
          }
        }

        &:before {
          left: 0;

          @include xs {
            top: 0;
            left: auto;
          }
        }

        &:after {
          right: 0;

          @include xs {
            bottom: 0;
            right: auto;
          }
        }
      }

      &__icon {
        width: auto;
        height: 6rem;
        margin-bottom: 2rem;

        @include md-lt {
          height: 5rem;
        }
      }

      &__title {
        margin-bottom: 2rem;
        display: block;
        color: var(--color-text);
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        text-align: center;
        font-family: var(--font-open-sans);
        font-weight: 400;

        @include md-lt {
          font-size: 2.2rem;
        }
      }

      &__list {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-right: 1.5rem;

        li {
          font-weight: 300;
          font-size: 1.4rem;
          margin-bottom: 1rem;
          letter-spacing: 1px;
          text-align: center;

          @include sm {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
