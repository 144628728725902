.about {
  &-content {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;

    @include sm-lt {
      flex-direction: column;
      align-items: center;
    }

    &__presentation {
      flex: 1.5;
    }

    &__picture {
      max-width: 26rem;
      margin-left: 4rem;

      @include md {
        max-width: 22rem;
        margin-left: 2rem;
      }

      @include sm-lt {
        margin: 2rem 0;
      }
    }
  }
}
