.portfolio {
    position: relative;

    .section {
        width: 100%;
        padding: 12rem 8rem 0 8rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @include lg {
            padding-right: 6rem;
            padding-left: 6rem;
        }

        @include md-lt {
            padding-right: 0;
            padding-left: 0;
        }

        @include mobile-landscape {
            padding-right: 0;
            padding-left: 0;
        }

        &__container {
            width: 100%;

            h2 {
                width: 100%;
                font-size: 2.8rem;
                font-weight: 500;
                font-family: var(--font-monospace);
                margin-bottom: 5rem;
                text-align: left;
                line-height: 3.5rem;

                @include sm-lt {
                    font-size: 2rem;
                }

                &::before {
                    content: "<h2> ";
                    color: var(--color-secondary);
                }

                &::after {
                    content: " </h2>";
                    color: var(--color-secondary);
                }
            }

            h3 {
                width: 100%;
                font-size: 2.6rem;
                font-weight: 400;
                font-family: var(--font-monospace);
                margin-bottom: 3rem;
                text-align: center;

                @include sm-lt {
                    font-size: 1.8rem;
                }

                i {
                    color: var(--color-secondary);
                    font-size: 1.8rem;
                }
            }

            p {
                font-size: 1.6rem;
                font-weight: 400;
                font-family: var(--font-open-sans);
                letter-spacing: 0.5px;
                line-height: 2.4rem;
                margin-bottom: 2rem;
            }
        }
    }
}
