@mixin side-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  z-index: 1;

  @include sm-lt {
    display: none;
  }

  @include mobile-landscape {
    display: none;
  }
}

.side-bar {
  &--social-networks {
    left: 4rem;
    @include side-bar;

    @include lg-lt {
      left: 1.5rem;
    }

    ul.social-networks {
      flex-direction: column;

      li {
        text-align: center;
      }
    }
  }

  &--contact {
    right: 4rem;
    @include side-bar;

    @include lg-lt {
      right: 1.5rem;
    }

    a {
      padding: 1rem;
      font-size: 1.2rem;
      writing-mode: vertical-lr;
      letter-spacing: 0.5px;
    }
  }

  &__vertical-line {
    width: 1px;
    height: 10rem;
    background-color: var(--color-text);
    margin-top: 2rem;
  }
}
