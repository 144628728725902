// -----------------------------------------------------------------------------
// This file contains all application-wide CSS/Sass variables.
// -----------------------------------------------------------------------------

// --------------------
// CSS variables.
// --------------------

:root {
    --color-white: #ddd;
    --color-grey: #1d1d1d;
    --color-dark-grey: #0c0c0c;
    --color-background: #141414;
    --color-text: #ced6e0;
    --color-primary: rgb(255, 20, 70);
    --color-primary-transparent: rgba(255, 20, 70, 0.8);
    --color-secondary: #08fdd8;

    --font-qebab: "Qebab-Shadow", "Open Sans", sans-serif;
    --font-open-sans: "Open Sans", sans-serif;
    --font-monospace: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
        monospace;

    --box-shadow: rgba(0, 0, 0, 0.6) 0px 14px 10px -10px,
        rgba(0, 0, 0, 0.4) 0px 10px 10px;
}

// --------------------
// Sass variables.
// --------------------

/// Breakpoints map.
/// @prop {String} keys - Keys are identifiers mapped to a given length.
/// @prop {Map} values - Values are actual breakpoints expressed in pixels.
$breakpoints: (
    "xs-max": 576px,
    "sm-min": 577px,
    "sm-max": 768px,
    "md-min": 769px,
    "md-max": 992px,
    "lg-min": 993px,
    "lg-max": 1200px,
    "xl-min": 1201px,
);

/// Relative or absolute URL where all assets are served from.
/// @type String
$url-assets: "/assets/";
